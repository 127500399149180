/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 17, 2021 */



@font-face {
    font-family: 'big_caslon_custom';
    src: url('big_caslon_medium-webfont.woff2') format('woff2'),
         url('big_caslon_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}