header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 20px;
  height: 80px;
  text-align: center;
  background-image: url(../../assets/img/bg.png);
  background-size: 32px 32px;
  background-attachment: fixed;
  transition: all ease 0.2s;
  z-index: 10;

  img {
    display: inline-block;
    height: 50px;
    transform: scale(0.8);
    transition: all ease 0.2s;

    &.text-img {
      margin-left: 0;
      width: 0;
      object-fit: cover;
    }
  }

  &.top {
    padding-top: 40px;
    height: 130px;

    img {
      height: 50px;
      transform: scale(1);

      &.text-img {
        margin-left: 6px;
        width: 150px;
      }
    }
  }
}

nav {
  position: fixed;
  top: 100%;
  left: 0;
  width: 350px;
  height: 100vh;
  padding-left: 40px;
  display: flex;
  align-items: center;
  z-index: 10;

  .nav-menu {
    &>div {
      margin-bottom: 44px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      position: relative;
      display: inline-block;
      cursor: pointer;
      font-size: 16px;
      text-transform: uppercase;
      transform-origin: left center;
      transition: all ease 0.2s;
      opacity: 0.6;
      text-decoration: none;
      color: #000;

      &.active {
        transform: scale(1.4);
        opacity: 1;
      }
    }
  }

  .socials {
    position: absolute;
    bottom: 24px;
    left: 40px;

    a {
      margin-right: 12px;
    }
  }
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
