#report .container {
  width: 100%;
}

#report .content {
  display: block;
}

#report .content .titles {
  text-align: center;
}

#report .description {
  margin-left: 0;
  margin-top: 24px;
}

#report .form {
  width: 100%;
  padding: 12px;
}
