#home {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
#glance {
  .glance-t2 {
    margin-top: 80px;
  }
}
#portfolio {
  .portfolio-t2 {
    margin: 80px 0 60px;
  }
}
#reports {
  padding-bottom: 20vh;
  line-height: 2;

  .report-link {
    text-decoration: none;
    color: var(--primary-color);

    small {
      margin-left: 12px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .report {
    margin-bottom: 80px;

    &.self {
      .title-t3 {
        margin-bottom: 12px;
      }
    }
  }
  .title-t3 {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
