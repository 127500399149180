@import url(./assets/font/big-caslon/stylesheet.css);
@import url(./assets/font/icon/iconfont.css);

* {
  box-sizing: border-box;
}

body, button {
  margin: 0;
  font-family: "big_caslon_custom", "PingFang SC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
    height: 100%;
    position: relative;
    font-size: 14px;
    color: var(--primary-color);
    background-color: #fff;
    background-image: url(./assets/img/bg.png);
    background-size: 32px 32px;
    background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.root {
  height: 100%;
}

.container {
  max-width: 1240px;
  padding: 0 20px;
  margin: auto;

  &.sm {
    max-width: 640px;
  }
}

.single-page {
  padding-top: 80px;
  padding-left: 350px;
  padding-right: 180px;
  padding-bottom: 40vh;
}


@media screen and (max-width: 1100px) {
  .single-page {
    padding-right: 60px;
  }
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}

